@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("/static/fonts/AvenirNextLTPro-Bold.eot");
  src: url("/static/fonts/AvenirNextLTPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/AvenirNextLTPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url("/static/fonts/AvenirNextLTPro-Demi.eot");
  src: url("/static/fonts/AvenirNextLTPro-Demi.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/AvenirNextLTPro-Demi.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url("/static/fonts/AvenirNextLTPro-Regular.eot");
  src: url("/static/fonts/AvenirNextLTPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/AvenirNextLTPro-Regular.ttf") format("truetype");
}

[data-reach-popover] {
  z-index: 1000;
}

[data-reach-popover] > div > div {
  max-height: 320px;
  overflow-y: auto;
}

[data-reach-menu-items]:focus {
  outline: none;
}

[data-reach-menu-button]{
  width: 100%;
}
